div#wheel {
  position: relative;
  transform: rotate(86deg);
}
canvas {
  width: 35vw !important;
}
div#wheel::before {
  content: '';
  background: url(../../assets/images/arrow.svg) center/cover no-repeat;
  background-size: 7vw;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-86deg);
  animation: pulse 2s linear forwards infinite;
}
@keyframes pulse {
  0%,
  100% {
    transform: translate(-50%, -50%) rotate(-86deg) scale(0.75);
  }
  50% {
    transform: translate(-50%, -50%) rotate(-86deg) scale(1);
  }
}
div#wheel::after {
  content: '';
  display: block;
  height: 1.2vw;
  width: 1.2vw;
  border-radius: 50%;
  background: black;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
