@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
div#wheelCircle {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: url(../../assets/images/background.svg) center/cover no-repeat;
}

.reward-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #236440;
  color: white;
  border: 0.125vw solid white;
  outline: none;
  width: 26.042vw;
  border-radius: 1.041665vw;
  padding: 2.0833vw;
  box-shadow:
    0px 11px 15px -7px rgba(0, 0, 0, 0.2),
    0px 24px 38px 3px rgba(0, 0, 0, 0.14),
    0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.reward-modal h2 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  margin-bottom: 1vw;
  font-size: 1.5vw;
}
.reward-modal p {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.025vw;
}
