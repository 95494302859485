@keyframes wave {
  0% {
    transform: scale(0.8);
    opacity: 1;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}
.mic-wrap {
  position: fixed;
  display: block;
  bottom: 3.2552vw;
  right: 3.2552vw;
  z-index: 1000000;
}
.mic-wrap.hide {
  display: none;
}
.mic-wrap .mic-icon {
  color: white;
  height: 3vw;
  width: 3vw;
}
.mic-wrap .mic-icon svg {
  height: 2vw;
  width: 2vw;
}
.wave-effect {
  position: absolute;
  width: 3vw;
  height: 3vw;
  border-radius: 50%;
  background-color: rgba(76, 175, 80, 0.5); /* Green background color with opacity */
  animation: wave 1.5s ease-out infinite;
  pointer-events: none;
  z-index: -1; /* Ensure the wave effect is behind the button */
}
