@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
*,
*::after,
*::before {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
img {
  display: block;
  max-width: 100%;
}
@font-face {
  font-family: 'fc_fastestbold';
  src: url('../../assets/fonts/fc_fastest_bold-webfont.eot');
  src:
    url('../../assets/fonts/fc_fastest_bold-webfont.eot?#iefix') format('embedded-opentype'),
    url('../../assets/fonts/fc_fastest_bold-webfont.woff2') format('woff2'),
    url('../../assets/fonts/fc_fastest_bold-webfont.woff') format('woff'),
    url('../../assets/fonts/fc_fastest_bold-webfont.svg#fc_fastestbold') format('svg');
  font-weight: normal;
  font-style: normal;
}
.landing-wrap {
  height: 100%;
  min-height: 100vh;
  font-family: 'fc_fastestbold';
  overflow: hidden;
  position: relative;
}
.landing-wrap.show-questions {
  position: relative;
  background: url(../../assets/images/question_bg.jpg) center/cover no-repeat;
  ::-webkit-scrollbar {
    width: 0.326vw;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0.326vw grey;
    border-radius: 0.651vw;
  }
  ::-webkit-scrollbar-thumb {
    background: #098039;
    border-radius: 0.651vw;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #06a144;
  }
}
.img-wrap {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  z-index: -1;
}

.slide {
  position: relative;
  bottom: 0;
  left: 0;
  height: 100%;
  -o-animation: bgSlide 21s ease-in-out forwards;
  -ms-animation: bgSlide 21s ease-in-out forwards;
  -moz-animation: bgSlide 21s ease-in-out forwards;
  -webkit-animation: bgSlide 21s ease-in-out forwards;
  animation: bgSlide 21s ease-in-out forwards;
}

.img-wrap1,
.img-wrap2,
.img-wrap3,
.img-wrap4 {
  width: 100%;
  height: 100%;
  line-height: 0;
}
.slide img {
  width: 100%;
  height: 100%;
}
.content-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  position: relative;
}

.logo-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  left: 0;
  right: 0;
  top: -100%;
  bottom: 100%;
  margin: 0 auto;
  -o-animation: moveLogo 7s linear 2s forwards;
  -ms-animation: moveLogo 7s linear 2s forwards;
  -moz-animation: moveLogo 7s linear 2s forwards;
  -webkit-animation: moveLogo 7s linear 2s forwards;
  animation: moveLogo 7s linear 2s forwards;
}
.logo-wrap img {
  height: 20vw;
  margin: 0 auto;
}
.logo-wrap h1 {
  color: white;
  font-size: 2vw;
  margin-top: 1vw;
  font-weight: 700;
}

.car-wrap {
  height: auto;
  width: 80vw;
  position: absolute;
  top: -100%;
  right: -100%;
  transform: rotate(12deg);
  -o-animation: moveCar2 8s ease-in-out 10s forwards;
  -ms-animation: moveCar2 8s ease-in-out 10s forwards;
  -moz-animation: moveCar2 8s ease-in-out 10s forwards;
  -webkit-animation: moveCar2 8s ease-in-out 10s forwards;
  animation: moveCar2 8s ease-in-out 10s forwards;
}
.car-wrap img {
  height: 100%;
}

.text-wrap {
  position: absolute;
  top: -100%;
  left: 7.8vw;
  max-width: 45.833vw;
  margin: 0 auto;
  text-align: center;
  -o-animation: moveText2 9s ease-in-out 10s forwards;
  -ms-animation: moveText2 9s ease-in-out 10s forwards;
  -moz-animation: moveText2 9s ease-in-out 10s forwards;
  -webkit-animation: moveText2 9s ease-in-out 10s forwards;
  animation: moveText2 9s ease-in-out 10s forwards;
}
.text-wrap .text {
  text-transform: uppercase;
  color: white;
  font-size: 5.75vw;
  line-height: 6.51vw;
  font-weight: 700;
  -webkit-text-stroke: 0.26vw #098039;
}

.about-wrap {
  position: absolute;
  top: -100%;
  text-align: center;
  -o-animation: moveText 25s ease-in-out 20s forwards;
  -ms-animation: moveText 25s ease-in-out 20s forwards;
  -moz-animation: moveText 25s ease-in-out 20s forwards;
  -webkit-animation: moveText 25s ease-in-out 20s forwards;
  animation: moveText 25s ease-in-out 20s forwards;
}
.about-wrap h2 {
  color: white;
  font-size: 5vw;
  line-height: 5vw;
  margin-bottom: 5.5vw;
  text-transform: uppercase;
  font-weight: 700;
  -webkit-text-stroke: 0.26vw #098039;
}
.about-inner ul {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  list-style: none;
  color: white;
  margin-bottom: -5.75vw;
}
.about-inner li {
  flex: 0 0 33.33%;
  max-width: 33.33%;
  margin-bottom: 5.75vw;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  -o-animation: fade 20s ease-in-out 23s forwards;
  -ms-animation: fade 20s ease-in-out 23s forwards;
  -moz-animation: fade 20s ease-in-out 23s forwards;
  -webkit-animation: fade 20s ease-in-out 23s forwards;
  animation: fade 20s ease-in-out 23s forwards;
}
.about-inner li h3 {
  font-size: 1.25vw;
}
.about-inner li .img-wrapper {
  height: 10.07vh;
  width: 4.558vw;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.75vw;
}
.img-wrapper img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.questions-wrap {
  opacity: 0;
  font-family: 'Montserrat', sans-serif;
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  max-height: 90vh;
  max-width: 68vw;
  width: 100%;
  overflow-y: auto;
  padding-right: 1.5vw;
}
.questions-wrap .question,
.questions-wrap h2 {
  font-size: 2vw;
  margin-bottom: 1vw;
  font-weight: 800;
}
.questions-wrap p {
  font-size: 1.5vw;
  font-weight: 400;
  line-height: 1.6;
  margin-bottom: 1vw;
}
.questions-wrap ul {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  list-style: none;
  margin: 0 -0.75vw;
}
.questions-wrap ul li {
  flex: 0 0 33.33%;
  max-width: 33.33%;
  margin-top: 1.5vw;
  display: flex;
  justify-content: center;
  padding: 0 0.75vw;
}
.questions-wrap ul li .img-wrapper {
  background-color: #1c4133;
  padding: 10px;
  border-radius: 6px;
}

.questions-wrap ul.one-col li {
  flex: 0 0 100%;
  max-width: 100%;
}
.questions-wrap ul.three-col li {
  flex: 0 0 33.33%;
  max-width: 33.33%;
}
.questions-wrap ul.two-col li {
  flex: 0 0 50%;
  max-width: 50%;
}
.questions-wrap ul.four-col li {
  flex: 0 0 25%;
  max-width: 25%;
}
.questions-wrap ul.five-col li {
  flex: 0 0 20%;
  max-width: 20%;
}
.questions-wrap ul.six-col li {
  flex: 0 0 16.66%;
  max-width: 16.66%;
}

.show-questions .text-wrap,
.show-questions .about-wrap,
.show-questions .about-inner li,
.show-questions .car-wrap,
.show-questions .logo-wrap,
.show-questions .slide {
  animation: none !important;
}
.show-questions .questions-wrap {
  -o-animation: showQuestions 2s linear 1s forwards;
  -ms-animation: showQuestions 2s linear 1s forwards;
  -moz-animation: showQuestions 2s linear 1s forwards;
  -webkit-animation: showQuestions 2s linear 1s forwards;
  animation: showQuestions 2s linear 1s forwards;
}

/* animations */

@keyframes bgSlide {
  0% {
    bottom: 0;
  }
  12.25%,
  40% {
    bottom: 100%;
  }
  50%,
  90% {
    bottom: 200%;
  }
  100% {
    bottom: 300%;
  }
}
@keyframes moveLogo {
  0% {
    top: -100%;
    bottom: 100%;
    animation-timing-function: ease-in-out;
  }
  25%,
  75% {
    top: 0;
    bottom: 0;
    animation-timing-function: linear;
  }

  100% {
    top: 100%;
    bottom: -100%;
    animation-timing-function: ease-in-out;
  }
}
@keyframes moveCar2 {
  0% {
    top: -100%;
    right: -100%;
  }
  25% {
    top: 50%;
    right: 10%;
    -o-transform: translateY(-50%) rotate(12deg);
    -ms-transform: translateY(-50%) rotate(12deg);
    -moz-transform: translateY(-50%) rotate(12deg);
    -webkit-transform: translateY(-50%) rotate(12deg);
    transform: translateY(-50%) rotate(12deg);
  }
  75% {
    top: 50%;
    right: 10%;
    -o-transform: translateY(-50%) rotate(12deg);
    -ms-transform: translateY(-50%) rotate(12deg);
    -moz-transform: translateY(-50%) rotate(12deg);
    -webkit-transform: translateY(-50%) rotate(12deg);
    transform: translateY(-50%) rotate(12deg);
  }
  100% {
    top: 100%;
    right: 100%;
    -o-transform: rotate(12deg);
    -ms-transform: rotate(12deg);
    -moz-transform: rotate(12deg);
    -webkit-transform: rotate(12deg);
    transform: rotate(12deg);
  }
}
@keyframes moveText2 {
  0% {
    top: -100%;
  }
  25% {
    top: 14vh;
  }
  75% {
    top: 14vh;
  }
  100% {
    top: 100%;
  }
}
@keyframes showQuestions {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes moveText {
  0% {
    top: -100%;
  }
  12.25%,
  90% {
    top: 50%;
    transform: translateY(-50%);
  }
  100% {
    top: 100%;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  12.25%,
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
