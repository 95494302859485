.search-page {
  background-image: url(../../assets/images/bg-2.jpg);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.search-box {
  margin-bottom: 20px;
  color: white;
  width: 500px;
}

.results {
  color: white;
}
